export const bgThemeImageList: Array<string> = [
	"none",
	"black-wave-background.png",
	"blue-wave-background.png",
	"navy-blue-wave-background.png",
	"dark-green-wave-background.png",
	"green-wave-background.png",
	"peach-wave-background.png",
	"yellow-wave-background.png",
	"msa-tool-hero-cta-red-gradient-bg.png",
	"msa-tool-hero-cta-blue-gradient-bg.png",
	"msa-tool-hero-cta-black-gradient-bg.png",
	"msa-tool-hero-cta-white-gradient-bg.png",
	"msa-tool-hero-cta-white-gradient-bg.png",
];

export const bannerHeroBgThemeImages: Array<string> = [
	"none",
	"lime-corner-background.png",
	"clc-corner-background.png",
	"hully-gully-corner-background.png",
	"ab-car-sales-corner-background.png",
	"carmoji-corner-background.png",
	"cg-open-road-corner-background.png",
	"headingly-powersports-corner-background.png",
	"kamloops-ford-corner-background.png",
	"oxford-dodge-corner-background.png",
	"cranbrook-van-mitsubishi-corner-background.png",
	"company-of-cars-corner-background.png",
	"the-power-garage-corner-background.png",
	"elite-auto-corner-background.png",
	"bow-mitsubishi-corner-background.png",
];

export const bannerInlineHeroBgSrcsetImages: Array<Record<string, any>> = [
	{
		bp: {
			"750px": "breeze-financial-ava-credit-1200w.jpg",
			"1200px": "breeze-financial-ava-credit-1920x500.jpg",
		},
		alt: "breeze-financial-banner-hero-credit-background",
		src: "breeze-financial-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "car-loans-canada-ava-credit-1200w.jpg",
			"1200px": "car-loans-canada-ava-credit-1920x500.jpg",
		},
		alt: "car-loans-canada--banner-hero-credit-background",
		src: "car-loans-canada-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "hully-gully-ava-credit-1200w.jpg",
			"1200px": "hully-gully-ava-credit-1920x500.jpg",
		},
		alt: "hully-gully-banner-hero-credit-background",
		src: "hully-gully-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "ab-car-sales-ava-credit-1200w.jpg",
			"1200px": "ab-car-sales-ava-credit-1920x500.jpg",
		},
		alt: "ab-car-sales-banner-hero-credit-background",
		src: "ab-car-sales-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "carmoji-ava-credit-1200w.jpg",
			"1200px": "carmoji-ava-credit-1920x500.jpg",
		},
		alt: "carmoji-banner-hero-credit-background",
		src: "carmoji-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "cg-open-road-ava-credit-1200w.jpg",
			"1200px": "cg-open-road-ava-credit-1920x500.jpg",
		},
		alt: "cg-open-road-banner-hero-credit-background",
		src: "cg-open-road-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "headingly-powersports-ava-credit-1200w.jpg",
			"1200px": "headingly-powersports-ava-credit-1920x500.jpg",
		},
		alt: "headingly-powersports-banner-hero-credit-background",
		src: "headingly-powersports-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "kamloops-ford-ava-credit-1200w.jpg",
			"1200px": "kamloops-ford-ava-credit-1920x500.jpg",
		},
		alt: "kamloops-ford-banner-hero-credit-background",
		src: "kamloops-ford-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "oxford-dodge-ava-credit-1200w.jpg",
			"1200px": "oxford-dodge-ava-credit-1920x500.jpg",
		},
		alt: "oxford-dodge-banner-hero-credit-background",
		src: "oxford-dodge-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "cranbrook-van-mitsubishi-ava-credit-1200w.jpg",
			"1200px": "cranbrook-van-mitsubishi-ava-credit-1920x500.jpg",
		},
		alt: "cranbrook-van-mitsubishi-banner-hero-credit-background",
		src: "cranbrook-van-mitsubishi-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "company-of-cars-ava-credit-1200w.jpg",
			"1200px": "company-of-cars-ava-credit-1920x500.jpg",
		},
		alt: "company-of-cars-banner-hero-credit-background",
		src: "company-of-cars-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "the-power-garage-rv-ava-credit-1200w.jpg",
			"1200px": "the-power-garage-rv-ava-credit-1920x500.jpg",
		},
		alt: "the-power-garage-rv-banner-hero-credit-background",
		src: "the-power-garage-rv-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "the-power-garage-powersports-ava-credit-1200w.jpg",
			"1200px": "the-power-garage-powersports-ava-credit-1920x500.jpg",
		},
		alt: "the-power-garage-powersports-banner-hero-credit-background",
		src: "the-power-garage-powersports-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "the-power-garage-marine-ava-credit-1200w.jpg",
			"1200px": "the-power-garage-marine-ava-credit-1920x500.jpg",
		},
		alt: "the-power-garage-marine-banner-hero-credit-background",
		src: "the-power-garage-marine-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "elite-auto-ava-credit-1200w.jpg",
			"1200px": "elite-auto-ava-credit-1920x500.jpg",
		},
		alt: "elite-auto-banner-hero-credit-background",
		src: "elite-auto-ava-credit-750w.jpg",
	},
	{
		bp: {
			"750px": "bow-mitsubishi-ava-credit-1200w.jpg",
			"1200px": "bow-mitsubishi-ava-credit-1920x500.jpg",
		},
		alt: "bow-mitsubishi-banner-hero-credit-background",
		src: "bow-mitsubishi-ava-credit-750w.jpg",
	},
];

export const allAssets: Record<string, Array<string>> = {
	equifaxLogos: [
		"powered-by-equifax-logo.svg",
		"Data-Provided-by-Equifax-w-128.png",
		"equifax-logo-white-backdrop.png",
		"equifax-logo-red.png",
		"equifax-logo-rounded-white-bg.svg",
		"equifax-logo-rounded-white-bg.png",
		"powered-by-equifax-logo-v2-small.png",
	],
	dealerLogos: [
		"mercedes-benz-logo.svg",
		"volvo-logo.svg",
		"bmw-logo.svg",
		"lexus-logo.svg",
		"mitsubishi-logo.svg",
		"subaru-logo.svg",
		"chrysler-logo.svg",
		"gmc-logo.svg",
		"jeep-logo.svg",
		"dodge-logo.svg",
		"ram-logo.svg",
		"lincoln-logo.svg",
		"chevrolet-logo.svg",
		"buick-logo.svg",
		"cadillac-logo.svg",
		"kia-logo.svg",
		"ford-logo.svg",
		"hyundai-logo.svg",
		"nissan-logo.svg",
		"honda-logo.svg",
		"toyota-logo.svg",
		"mazda-logo.svg",
		"volkswagen-logo.svg",
		"gm-logo.svg",
		"santander-logo-white.svg",
		"breeze-financial-solutions-logo.svg",
		"car-loans-canada-logo.svg",
		"hully-gully-logo.svg",
		"ab-car-sales-logo.svg",
		"carmoji-logo.svg",
		"cg-open-road-logo.svg",
		"headingly-powersports-logo.svg",
		"kamloops-ford-logo.svg",
		"oxford-dodge-logo.svg",
		"cranbrook-van-mitsubishi-logo.svg",
		"company-of-cars-logo.svg",
		"the-power-garage-logo.svg",
		"elite-auto-logo.svg",
		"bow-mitsubishi-logo.svg",
	],
	launchButtons: [
		"see-if-you-qualify-red-white.svg",
		"start-now-red-white-white.svg",
		"get-started-button-white-red.svg",
		"get-started-button-yellow.svg",
		"start-your-pre-approval-yellow-white-white.svg",
		"start-your-pre-approval-navy-blue-white-white.svg",
		"start-your-pre-approval-baby-blue-white-white.svg",
		"get-pre-approved-red-white-black.svg",
		"get-started-fifth-ave-vw.svg",
		"get-your-free-score-button-orange-white-white.svg",
		"get-your-loan-rates-button-red-white-white.svg",
		"get-started-button-no-rocket-green-white.svg",
		"get-your-free-score-button-red-white-black.svg",
		"get-pre-approved-lime-white-white.svg",
		"free-credit-score-button-teal-white-black.svg",
		"get-pre-approved-yellow-black-yellow.svg",
		"check-your-score-teal-white-black.svg",
		"start-your-pre-approval-orange-white-white.svg",
		"start-your-pre-approval-red-white-white.svg",
		"get-qualified-now-button.svg",
		"get-your-free-score-button.svg",
		"get-your-free-score-button-w-white-graphic.svg",
		"get-started-no-input-trade-cta.jpg",
		"get-started-no-input-trade-cta.svg",
		"check-your-rate-red-white.svg",
		"check-your-rate-red-white-black.svg",
		"check-your-rate-shimmer-black.svg",
		"check-your-rate-shimmer.svg",
		"get-pre-approved-red-white.svg",
		"check-your-rate-grey-button-white-text.svg",
		"get-started-grey-button.svg",
		"get-started-white-black-square.svg",
		"check-your-rate-white-black-white-square.svg",
		"check-your-score-red-with-black-text.svg",
		"get-your-free-score-button-white-black-white.svg",
		"get-a-quote-red-white.svg",
		"free-credit-check-button-red-white-white.svg",
		"mercedes-get-your-free-score-button-white-black-white.svg",
		"volvo-get-your-free-score-button-white-navyblue-white.svg",
		"bmw-get-your-free-score-button-blue-white-white.svg",
		"lexus-get-your-free-score-button-bluestone-white-white.svg",
		"mitsubishi-get-your-free-score-button-white-red-white.svg",
		"subaru-get-your-free-score-button-white-skyblue-white.svg",
		"chrysler-get-your-free-score-button-white-lightblue-white.svg",
		"gmc-get-your-free-score-button-white-red-white.svg",
		"jeep-get-your-free-score-button-yellow-white-white.svg",
		"dodge-get-your-free-score-button-white-crimsonred-white.svg",
		"ram-get-your-free-score-button-white-crimsonred-white.svg",
		"lincoln-get-your-free-score-button-white-slate-white.svg",
		"chevrolet-get-your-free-score-button-mustard-white-white.svg",
		"buick-get-your-free-score-button-white-orange-white.svg",
		"cadillac-get-your-free-score-button-gold-black-white.svg",
		"kia-get-your-free-score-button-white-black-white.svg",
		"ford-get-your-free-score-button-white-blue-white.svg",
		"hyundai-get-your-free-score-button-white-darkblue-white.svg",
		"nissan-get-your-free-score-button-white-black-white.svg",
		"honda-get-your-free-score-button-white-black-white.svg",
		"toyota-get-your-free-score-button-white-black-white.svg",
		"mazda-get-your-free-score-button-white-black-white.svg",
		"volkswagen-get-your-free-score-button-white-black-white.svg",
		"santander-get-your-loan-rates-button-white-red-white.svg",
		"santander-get-your-loan-rates-button-red-white-white.svg",
		"get-your-free-score-square-button-lime-white-white.svg",
		"clc-get-your-free-score-square-button-green-white-white.svg",
		"hully-gully-get-your-free-score-square-button-red-white-white.svg",
		"ab-car-sales-get-your-free-score-square-button-red-white-white.svg",
		"carmoji-get-your-free-score-square-button-blue-white-white.svg",
		"cg-open-road-get-your-free-score-square-button-blue-white-white.svg",
		"headingly-powersports-get-your-free-score-square-button-red-white-white.svg",
		"kamloops-ford-get-your-free-score-square-button-navyblue-white-white.svg",
		"oxford-dodge-get-your-free-score-square-button-green-white-white.svg",
		"bathurst-toyota-french-credit-get-your-score-button-white-red-white.svg",
		"bathurst-toyota-french-trade-start-button-white-red.svg",
		"french-credit-get-your-score-button-black-white-white.svg",
		"french-credit-get-your-score-button-blue-white-white.svg",
		"french-credit-get-your-score-button-green-white-white.svg",
		"french-credit-get-your-score-button-red-white-white.svg",
		"french-credit-get-your-score-button-white-black-black.svg",
		"cranbrook-van-mitsubishi-get-your-free-score-square-button-red-white-white.svg",
		"company-of-cars-get-your-free-score-square-button-pink-white-white.svg",
		"get-your-free-score-button-white-black-black.svg",
		"the-power-garage-get-your-free-score-square-button-red-white-white.svg",
		"elite-auto-get-your-free-score-square-button-black-white-white.svg",
		"bow-mitsubishi-get-your-free-score-square-button-red-white-white.svg",
		"get-started-button-font-light-white-black-white.svg",
		"get-pre-approved-button-font-bold-white-black-white.svg",
		"get-started-button-font-bold-white-black-white.svg",
		"get-pre-approved-button-font-light-white-black-white.svg",
		"lets-go-button-rocket-white-black.svg",
		"get-started-button-white-black.svg",
		"get-started-button-rocket-white-black.svg",
		"lets-go-button-white-black.svg",
		"cbb-trade-car-input.svg",
		"cbb-trade-marine-input.svg",
		"cbb-trade-powersports-input.svg",
		"cbb-trade-rv-input.svg",
		"cbb-trade-french-car-input.svg",
		"get-your-trade-in-value-button-white-black.svg",
		"get-your-trade-in-value-rounded-button-white-black.svg",
		"get-approved-button-green-white-black.svg",
		"get-approved-button-green-white-white.svg",
		"get-approved-button-white-green-white.svg",
		"get-pre-approved-button-green-white-black.svg",
		"get-pre-approved-button-green-white-white.svg",
		"get-your-free-score-button-green-white-white.svg",
		"get-your-free-score-button-green-white-black.svg",
		"start-now-button-orange-white-white.svg"
	],
};
